import React from 'react'

const HeaderCarousel = ({index, name, move }) => {
   
    return (
        <section className="inner-page-text-section padding-140 padding-top-0">
            <div className="btn-utility-gallery margin-top-58">
                <span className="hashtag-tattoo-artist">#{name}</span>
               <div className="btns-container">
               
                <div className={"btn-gallery btn-gallery--left btn-gallery-"+ index} onClick={()=>move("left")}>
                    <div className="arrow-btn-gallery-container">
                        <span className="arrow-line arrow-line--left"></span>
                    </div>
                </div>
                <div className={"btn-gallery btn-gallery--right  btn-gallery-"+ index} onClick={()=>move("right")}>
                    <div className="arrow-btn-gallery-container">
                        <span className="arrow-line arrow-line--right"></span>
                    </div>
                </div>
               </div>
            </div>
        </section>

 
    )
        
      
}

export default HeaderCarousel
