import React, { useEffect, useState } from 'react'

const Modal = ({modalPath,setModalPath}) => {
  const [newImage, setNewImage] = useState(null)
  const [dataIndex,setDataIndex] = useState(0)
  
  useEffect(() => {
    setNewImage(modalPath?.data[modalPath?.index]?.path)
    setDataIndex(modalPath?.index)
  }, [modalPath])
  
  function showNew(symbol) {
    setDataIndex(symbol === "-" ? dataIndex - 1 : dataIndex + 1)
    if (dataIndex <= 0 && symbol === "-") {
      setDataIndex(modalPath?.data.length - 1)
    }

    if (dataIndex >= modalPath?.data.length - 1 && symbol === "+") {
      setDataIndex(0)
    }
  }
console.log(dataIndex);
  useEffect(() => {
    setNewImage(modalPath?.data[dataIndex]?.path)
  },[dataIndex,modalPath])
  
  return (
    <section className="modale" id="modale" style={newImage ? {display:"flex"}: {display:"none"}}>
    <div className="modale-container">
        <img id="modale-img" src={"assets/images/uploads/" + newImage} alt=""/>
        <button id="modale-exit" onClick={()=>setModalPath(null)}>X</button>
    </div>

    <div className="modale-prev-img-btn modale-img-btn" id="prev-btn" onClick={()=>showNew("-")}></div>
    <div className="modale-next-img-btn modale-img-btn" id="next-btn" onClick={()=>showNew("+")}></div>
    
    </section>
  )
}

export default Modal
