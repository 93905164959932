import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NewAuthor from './screens/Authors/NewAuthor/NewAuthor'
import Login from './screens/Login/Login'
import Home from './screens/Home/Home'
import Authors from './screens/Authors/Authors'
import SingleAuthor from './screens/Authors/SingleAuthor'
import GeneralSetting from './screens/GeneralSetting/GeneralSetting'
import NewAlbum from './screens/Albums/NewAlbum/NewAlbum'
import Albums from './screens/Albums/Albums'
import OpeningTimes from './screens/OpeningTimes/OpeningTimes'
import OpeningTimesEdit from './screens/OpeningTimes/OpeningTimesEdit'
import GeneralSettingEdit from './screens/GeneralSetting/GeneralSettingEdit'
import EditAlbum from './screens/Albums/NewAlbum/EditAlbum'

const Admin = () => {


  return (
    <div className='admin'>
      <Routes>
        <Route path='*' element={"error"} />
        <Route path='/' element={<Login/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/authors/newAuthor' element={<NewAuthor/>} />
        <Route path='/authors' element={<Authors/>} />
        <Route path='/author/:id' element={<SingleAuthor />} />
        <Route path='/general-settings' element={<GeneralSetting/>} />
        <Route path='/general-settings/edit' element={<GeneralSettingEdit/>} />
        <Route path='/new-album' element={<NewAlbum/>} />
        <Route path='/albums' element={<Albums/>} />
        <Route path='/albums/:id' element={<EditAlbum/>} />
        <Route path='/opening-times' element={<OpeningTimes/>} />
        <Route path='/opening-times/edit' element={<OpeningTimesEdit/>} />
        </Routes>   
    </div>
  )
}

export default Admin
