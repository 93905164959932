import React from 'react'
import Form from './Form'
import StudioInfos from './StudioInfos'
import OpeningTimes from './OpeningTimes'

const InfoContact = ({getInfo}) => {

    const getInfoForFooter = (data) => {
        getInfo(data)
    }

return (
      
<section className="page" id="info">
        <section className="inner-page-text-section padding-140">
            <p className="title-page">INFO E CONTATTI</p>
            <h2 className="description-title-page">Contattaci</h2>
            <hr className="separator-in-page" />
        </section>

        <section className="info-body-section padding-140">
            <div className="info-body-inner info-body-inner--flex">
                <div className="info-container info-container1">
                    <p className="info-title">PRENOTA UN APPUNTAMENTO</p>
                    <p
                        className="info-form-info">
                        Inserisci le info per l'appuntamento, ti è possibile allegare
                        anche immagini e lasciare un messaggio. Ti ricontatteremo il prima possibile
                    </p> 
                    <Form/>
                </div>

                <StudioInfos getInfoForFooter={(data)=> getInfoForFooter(data)}/>
                <OpeningTimes/>
            
            </div>
        </section>
</section>
  )
}

export default InfoContact
