import React from 'react'

const Form = () => {
  return (
    <form action="#" className="form-contact" id="form-contact" encType="multipart/form-data">
    <div className="form-header-inputs">

        <div className="form-header-top inputs-container">
            <input type="text" name="input-username" id="input-username" required  placeholder="Nome" className="input"  maxLength="20"/>
            <div className="inpt-date-container inpt-container--relative">
                <label htmlFor="input-date">Scegli il giorno</label>
                <input type="date" name="input-date" id="input-date" required  className="input"/>
            </div>
        </div>

        <div className="form-header-bottom inputs-container">
            <input type="email" name="input-email" id="input-email" required className="input" placeholder="E-mail"/>
            <div className="inpt-date-container inpt-container--relative">
                <label htmlFor="input-hour">Scegli l'orario</label>
                <input type="time" name="input-hour" id="input-hour" required className="input"/>
            </div>
        </div>
    </div>

    <div className="form-middle-textarea">
        <textarea name="input-message" id="input-message" cols="30" rows="10" required placeholder="Scrivi un messaggio" maxLength="500"></textarea>
    </div>

    <div className="form-footer-inputs inputs-container">
        <label htmlFor="input-file"className="input"> 
            <div className="upload-img" id="upload-container"></div>
                 Allega immagine (Max 8MB) 
        </label>
        <input type="file" name="input-file" id="input-file" accept="image/*"/>
        <input type="submit" value="PRENOTA APPUNTAMENTO" className="input" id="submit"/>
    </div>
    <p className="form-warning-text" id="form-warning-text-name">* Il nome deve contenere solo lettere, massimo 20 caratteri</p>
    <p className="form-warning-text" id="form-warning-text-email">* L'email non è in un formato corretto</p>
    <p className="form-warning-text" id="form-warning-text-message">* Per motivi di sicurezza nel Messaggio alcuni caratteri non sono ammessi</p>
    <p className="form-warning-text" id="form-warning-text-file">* L'immagine deve avere una grandezza massima di 8MB, riprova</p>
</form>
  )
}

export default Form
