import React, { useEffect, useState } from "react";

const HeroBrand = () => {
  const [scrollY, setScrollY] = useState(window.scrollY)
    
     
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollY(window.scrollY)
        })
        return () => window.removeEventListener("resize", () => {
            setScrollY(window.scrollY)
        })

    },[])
    console.log(scrollY);
  return (
    <>
      <section className="page padding-140" id="default">
        <div className="inner-default--flex inner-page">
          <div className="title-description-container">
            <h1 className="title">
              BLACK <br />
              ROSE <br />
              TATTOO
            </h1>
            <p className="title-description">
              Venite a trovarci oggi stesso e lasciate che vi aiutiamo a
              trasformare i vostri sogni in realtà e...
              <span className="word-in-text-desc-default">inchiostro!</span>
            </p>
            <a href="#info" style={{textDecoration:"none"}}><p className="btn-underTitle">PRENOTA UN APPUNTAMENTO</p></a>
          </div>
          <div className="hero-logo-container" id="hero-logo-container" style={scrollY > 300 && window.innerWidth > 1024 ? {opacity:0.05} : {opacity:1}}>
            {/* <!-- <div className="circle-hero">
            <div className="hero-logo-img">Black Rose</div>
        </div> --> */}
            <img draggable={false} className="helogo"  src="assets/svg/1_Hero_Logo.svg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroBrand;

