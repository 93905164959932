import React from 'react'


const InputEditor = ({  title, onClose, children}) => {

  return (
    <div className='bg-opacity-75' style={{position:"fixed",backgroundColor:"light",width:"100vw",height:"100vh",top:0,zIndex:10}}>

    <div className="position-fixed bg-white border-3 w-75 h-50 rounded" style={{top:"50%",left:"50%",border:"1px solid black",display:"block",transform:"translate(-50%,-50%)"}}>
        <div>
              <h4 className='text-center mt-1'>{title}</h4>
          <div className="float-end position-absolute bottom-0" style={{ right: 10, cursor: "pointer", width: 40, height: 40 }} onClick={onClose}>
            <div className="btn btn-danger" style={{fontSize:16}}>X</div>
           
          </div>
        </div>

      {children}
      </div>
    </div>
  )
}

export default InputEditor
// {htmlFor,inptType,inptName,label,onChange,style,placeholder,image,value, required})