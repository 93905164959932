
import React, { useEffect, useState } from 'react'


const Header = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [scrollY, setScrollY] = useState(window.scrollY)
    const [lastScrollY, setLastScrollY] = useState(window.scrollY)
    const [hideHeader,setHideHeader] = useState(false)
    
     
    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenSize(window.innerWidth)
        })
        return () => window.removeEventListener("resize", () => {
            setScreenSize(window.innerWidth)
        })

    }, [])
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollY(window.scrollY)
            if (scrollY >= lastScrollY + 100 ) {
                setLastScrollY(scrollY)
                setHideHeader(true)
            }else if (scrollY <= lastScrollY - 100 ) {
                    setLastScrollY(scrollY);
                    setHideHeader(false) 
                  }
        })
        return () =>  window.removeEventListener("scroll", () => {
            setScrollY(window.scrollY)
            if (scrollY >= lastScrollY + 100 ) {
                setLastScrollY(scrollY)
                setHideHeader(true)
            }else if (scrollY <= lastScrollY - 100 ) {
                setLastScrollY(scrollY);
                setHideHeader(false) 
            }
        })

    },[lastScrollY,scrollY])
   
   
  return (
    <header className={`header padding-140 ${hideHeader && "scroll-to-top"}`}>
        <div className="logo-container">
        <div className="logo">
            <img src="/assets/icons/Raggruppa 66.png" alt="black rose tattoo" title="black rose tattoo" width={150} height={92}/>
        </div>
        </div>
        <div className="header-right-elements">
        <nav className="nav" id='header__nav'>
            <ul className="nav-container" id='nav-container'>
                <li className="nav-item"><a href="#galleria" className="nav-item__link">GALLERIA</a></li>
                <li className="nav-item"><a href="#tatuatori" className="nav-item__link">TATUATORI</a></li>
                <li className="nav-item"><a href="#studio" className="nav-item__link">STUDIO</a></li>
                <li className="nav-item nav-item--hidden-first-line" id="nav-item--hidden-first-line"><a href="#info" className="nav-item__link">{screenSize < 1106 ? "INFO" : "INFO E CONTATTI"}</a></li>
            </ul>
        </nav>
        <div className="nav-item nav-item-button-contact ">
            <a href="#contattaci" className="nav-item__link">
                <div className="nav-item--big">
                    <div className="icon-whatsapp"></div>
                        <p className="text-link-Btn-Nav">CONTATTACI</p>
                </div>
            </a>
        </div>
        </div>
    </header>
)
}
    

export default Header;


