import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from '../../../../Axios/Axios'

const SectionStudio = () => {
    const { isLoading, isError, data, error } = useQuery("studioImages", () => {
        return api.get("gallery/2").then(response => response.data)
    })
const [array,setArray]= useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

console.log(data);
    
    return (
    <section className="page" id="studio">
    <section className="inner-page-text-section padding-140">
        <p className="title-page">STUDIO</p>
        <h2 className="description-title-page">Il nostro studio</h2>
        <hr className="separator-in-page" />
        <div className="btn-utility-gallery"></div>
    </section>
    <section className="studio-section-desc-img padding-left-140">
        <div className="studio-desc-img-inner">
            <div className="studio-container studio-container--flex">
                <section className="studio-gallery-section">
                    <div className="studio-gallery-inner">
                        <div className="studio-gallery-container">
                            <ul className="studio-gallery-slider studio-gallery-slider--grid">
                                <li className="studio-card-container grid-1">
                                    <div className="studio-card studio-card-desc">
                                        <p className="studio-card-text">
                                            Siete alla ricerca di un fantastico studio di tatuaggi?
                                            Non cercate oltre! I nostri artisti esperti e di talento
                                            lavoreranno con voi per creare un disegno unico che rifletta
                                            il vostro stile individuale.
                                        </p>
                                        <p className="btn-underTitle btn-underTitle-studio-section">PRENOTA UN APPUNTAMENTO</p>
                                    </div>
                                </li>
                                        
                                {
                                    data && array.map((img, index) =>
                                        <li key={index} className={`studio-image-list grid-${index + 2}`}>
                                            <img
                                                src={`/assets/images/uploads/${data[0]?.path}`}
                                                alt={data[0]?.description}
                                            />
                                        </li>
                                    )
                                
                                } 
                            </ul>
                            <p className="btn-underTitle btn-underTitle-studio-section btn-show-studio-img hidden-LG" id="btn-handle-showpics">CARICA ALTRE FOTO</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</section>

    
  )
}

export default SectionStudio
