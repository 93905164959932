import React, { useState } from 'react'
import { QueryClient, QueryClientProvider} from "react-query";
import { Routes,Route} from 'react-router-dom'
import NewAuthor from './components/admin/Admin'
import PublicRoute from './components/publicRoute/PublicRoute'
import Admin from './components/admin/Admin'
import ProtectedRoute from './components/admin/ProtectedRoute';
const queryClient = new QueryClient({});


const App = () => {
  
return (
<>
      <QueryClientProvider client={queryClient}>
      <Routes>
        
        {/* //Public Routes */}
        <Route exact path='/' element={<PublicRoute />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute bool={true} />}>
            <Route path='/admin/*' element={<Admin/>} />
        </Route>
        
      

      </Routes>
      </QueryClientProvider>

</>
)
}

export default App
