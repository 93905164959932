import React, { useEffect, useState} from "react";
import api from "../../../../Axios/Axios";

import CardInfo from "./CardInfo";


const TattooImages = ({
  albumId,
  nameInCard,
  authorInfo,
  setImgSize,
  setModalPath,
  isMouving,
}) => {
  const [imgSizeISDefined, setImgSizeIsDefined] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (albumId) {
      api.get(`gallery/` + albumId).then((response) => {
        setData(response.data);
      });
    }
  }, [albumId]);

  function checkImage(index) {
    setData(data.filter((d, i) => d[i] !== d[index]));
  }

  return (
    <>
       
      <CardInfo
        tel={authorInfo.tel}
        instagram={authorInfo.instagram}
        facebook={authorInfo.facebook}
        imagePath={authorInfo.imagePath}
      />
    

      {data?.map((img, index) => {
        return (
          <figure
            key={index}
            className="img-container--slider-tatuatori img-container-tatuatori--first"
            onClick={(e) => !isMouving && setModalPath({ data, index })}
          >
            <img
              className="img-tatoo"
              onError={() => checkImage(index)}
              onLoad={(e) => {
                !imgSizeISDefined && setImgSize(e.target.width);
                setImgSizeIsDefined(true);
              }}
              src={"/assets/images/uploads/" + img.path}
              alt={
                img.description ??
                " Un altro bellissimo lavoro realizzato da " +
                  nameInCard[0]?.toUpperCase() +
                  nameInCard.slice(1).replaceAll(",", "").toLowerCase() +
                  ", targato Black Rose Studio. Chiamaci per scoprire il tatuaggio che più si adatta al tuo stile personale!"
              }
              width={397}
              height={560}
            />
            <figcaption className="img-tatoo-description--hover">
              <div className="hover-background"></div>
              <section className="icon-details-container--hover">
                <div className="icon-card--hover"></div>
                <p className="details-card--hover">PIÙ DETTAGLI</p>
              </section>
              <section className="description-card-container--hover">
                <p className="hashtag-card--hover">{img.title}</p>
                <p className="description-card--hover">{img.description}</p>
              </section>
            </figcaption>
          </figure>
        );
      })}
    </>
  );
};

export default TattooImages;
