import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);


reportWebVitals();


//  const files = ["/public/assets/svg/1_Hero_Logo.svg","/public/assets/images/map-gogglemaps-blackrose.jpg"]
// if ('caches' in window) {
//     caches.open('my-cache-name')
//       .then(cache => {
//         return cache.addAll(files);
//       })
//       .then(() => {
//         console.log('Immagine memorizzata nella cache con successo.');
//       })
//       .catch(error => {
//         console.error('Errore durante il salvataggio nella cache:', error);
//       });
//   }

// window.addEventListener("load", () => {

//     //momentaneaooo
//     if (document.querySelector('#nav-item--hidden-first-line a')) {
        
    
//   const navItemContact = document.querySelector('#nav-item--hidden-first-line a');
//   const firstCarousel = document.querySelector(".inner-carousel-section");
//   const artistCarousels = document.querySelectorAll(".artist-slider-carousel-container");
//   const buttonsRightCarousels = document.querySelectorAll(".btn-gallery");
//   const carouselArtistCards = document.querySelectorAll(".artist-slider-carousel-container .img-container--slider-tatuatori");
//   const artistCardNamePositionX = document.querySelector(".artist-card-name")?.getBoundingClientRect().x;
//   const modaleImg = document.querySelector("#modale-img");
//   const modaleExitBtn = document.querySelector("#modale-exit");
//   const nextBtnModale = document.querySelector("#next-btn"); 
//   const previousBtnModale = document.querySelector("#prev-btn"); 
//   let isMouseHover = false; 
//   let isScroll = false;
//   let isMoving = false;
//   let imgTargetModale;
//   let typeOfTarget;
//   let mousePosInitialX = 0;
//   let BtnIsPressed = false;
//   /*
//   ******** Handle Window Events ************
//   -- includes header animation (move to top) & hero animation(opacity)
//   */
//   function handleWindowScroll() {
//       let scrollPosition = 0;
//       const hero = document.querySelector("#hero-logo-container");
  
//       window.addEventListener("scroll", () => {
//           const header = document.querySelector("header");
//           let scrollY = window.scrollY;
//           handleHeroOpacity();
  
//   //check scrool position if > or < then last position
//           if (scrollY >= scrollPosition + 100 && header) {
//               scrollPosition = scrollY;
//               header.style.top = "-100%"
              
//           }else if (scrollY <= scrollPosition - 100 && header) {
//               scrollPosition = scrollY;
//               header.style.top = "0%"  
//           }
//       })
      
  
  
  
//       function handleHeroOpacity(){
//           const scrollY = window.scrollY;
      
//           if (scrollY > 300 && window.innerWidth > 1024 && hero) {
//               hero.style.opacity = "0.05";
              
//           } else {
//               hero.style.opacity = "1";
//           }
//       }
  
//   //Call function for hero animation (opacity)
//       handleHeroOpacity();
//   } 
  
//   //Call Function HandleHeaderScroll  
//   handleWindowScroll();
  
  
//   /*
//       Handle Window Resize Event -> change nav-item  in navigation from "Info & contact" to "Info",
//       depending of the screen width
//   */
  
  
  
  
//   window.addEventListener("resize", (e) => {
  
//       //if screen <= 1100 text = INFO
//       if (window.innerWidth <= 1100) {
  
//           navItemContact.textContent = "INFO";
  
//            const secondGeneralCarousel = document.querySelectorAll(".second-carousel div");
//                secondGeneralCarousel.forEach(card => {
//                if (!card.dataset.type) {
//                    card.dataset.type = "second";
//                }
//                document.querySelector(".first-carousel").appendChild(card)
//            })
  
//             //if screen > 1200 text = INFO
//       } else if (window.innerWidth > 1100) {
//           navItemContact.textContent = "INFO E CONTATTI";
  
//           const firstCarousel = document.querySelectorAll(".first-carousel div");
  
//           firstCarousel.forEach(card => {
//               if (card.dataset.type === "second") {
//                   const secondGeneralCarousel = document.querySelector(".second-carousel");
//                   secondGeneralCarousel.appendChild(card)
//               }
//           })
//       }
  
    
//   })
  
//   //On start layout if screen-width <= 1020 text = INFO
//   if (window.innerWidth <= 1020) {
//       navItemContact.textContent = "INFO";
  
//       const secondGeneralCarousel = document.querySelectorAll(".second-carousel div");
//       secondGeneralCarousel.forEach(card => {
//           if (!card.dataset.type) {
//               card.dataset.type = "second"
//           }
//           document.querySelector(".first-carousel").appendChild(card);
//       })
//   }
  
  
//   //>--------------------------------------------------------------------------------------------------------------------------------<//
//   /*
//       Handle Carousels Scroll animation (button input , touch scroll , mouse scroll)
//   */
  
//   buttonsRightCarousels.forEach(element => {
     
//       element.addEventListener("mousedown", (e) => {
//           let buttonName = e.currentTarget;
//           BtnIsPressed = true;
          
//           switch (buttonName.classList[2]) {
//               case "btn-gallery-0":
//                       moveSlider(firstCarousel,e.target);
//                   break;
              
//                   case "btn-gallery-1":
//                       moveSlider(artistCarousels[0],e.target);
//                   break;
              
//                   case "btn-gallery-2":
//                       moveSlider(artistCarousels[1],e.target);
//                   break;
              
//                   case "btn-gallery-3":
//                       moveSlider(artistCarousels[2],e.target);
//                   break;
          
//               default:
//                   break;
//           }
//       },true)
//   });
  
  
//   // Handle scroll for PC --mouse
//   function moveSlider(slider, button) {
//       const imgWidth = slider.querySelector("img").getBoundingClientRect().width;
//       const direction = button.classList[1]?.includes("right")
      
  
//           if (direction) {
//               slider.scrollTo({
//                   top: 0,
//                   left: slider.scrollLeft + 260 + 18,
//                   behavior:'smooth'
//               });
//               console.log(((window.innerWidth - imgWidth)*2));
  
//           } else {
//               slider.scrollTo({
//                   top: 0,
//                   left: slider.scrollLeft -  (260 + 18),
//                   behavior:'smooth'
//               });
//           }   
      
  
//       window.addEventListener("mouseup", () => {
//           BtnIsPressed = false;
//       })
  
//       window.addEventListener("mouseleave", () => {
//           BtnIsPressed = false;
//       })
      
//   }
  
  
  
  
//   // event listener for all image cards while scrolling --> change opacity
  
//   artistCarousels.forEach(function (slider) {
//       slider.addEventListener("scroll", () => {
          
//           changeCardOpacity(carouselArtistCards);
//       })
//   })
  
//   // FUNCTION Change opacity when img card tatoo < Artist name (x position)
//   function changeCardOpacity(cards) {
//       let limitCardXOnScreen = artistCardNamePositionX + 100;
      
//       if (window.innerWidth < 640) {
//           limitCardXOnScreen = artistCardNamePositionX - 50 ;
//       }
     
//       cards.forEach(card => {
//           let cardPosition = card.getBoundingClientRect();
//           let x = cardPosition.x;
//           if (x < limitCardXOnScreen) {
//               card.classList.add("lower-opacity");
//           } else {
//               card.classList.remove("lower-opacity");
//           }
//       });
//   }
  
  
//   /* 
//       FOR ALL IMAGES :
//       Set attribute DRAGGABLE OFF to all images , Scroll bux fixed now
//   */
  
//   document.querySelectorAll("img").forEach(img => {
//        img.setAttribute("draggable", "false");
//   })
  
  
//   //>--------------------------------------------------------------------------------------------------------------------------------<//
  
//   //HANDLE SHOW PICS STUDIO SECTION
  
//   document.querySelector("#btn-handle-showpics").addEventListener("click", (e) => {
  
//       [...document.querySelectorAll(".handle-display")].map((img) => {
//           img.classList.toggle("img-hidden");
//       })
  
//       e.target.innerText = e.target.innerText === "CARICA ALTRE FOTO" ? "MOSTRA MENO FOTO" : "CARICA ALTRE FOTO" ;
//   })
//   //>--------------------------------------------------------------------------------------------------------------------------------<//
  
//   /*
//   ***************** Form Contact Section **********************
//   includes:
  
//   Handle image 
//   Handle form input errors 
//   Handle Reservation, sending data with submit button
//   Handle Image File Size
//   Handle inputs date & time --functions
  
//   TEST FOR SEND DATA FOR WORKAPP
//   */
  
      
  
     
//       //validate all inputs
      
  
//   const form = document.querySelector("#form-contact");
//   form.addEventListener("submit", sendData);
  
  
  
//   document.querySelector("#input-username").addEventListener("input", (e) => {
//       checkInputValidity("name", e.target, document.querySelector("#form-warning-text-name"));
//   })
  
  
//    document.querySelector("#input-email").addEventListener("input", (e) => {
//        checkInputValidity("email", e.target, document.querySelector("#form-warning-text-email"));
//    })
   
//    document.querySelector("#input-message").addEventListener("input", (e) => {
//        checkInputValidity("message", e.target, document.querySelector("#form-warning-text-message"));
//    })
   
      
  
//   function checkInputValidity(inputType, input, warningElement) {
  
//       switch (inputType) {
//           case "name":
//               const username = document.querySelector("#input-username").value.trim();
//               let usernameIsValid = (!username.match(/[-&\.;,=)_(&%$£"'!|\\/*-+.?^@°*0-9]/g)) && (username.length <= 20);
//               if (usernameIsValid) {
//                   input.classList.remove("warning");
//                   warningElement.classList.remove("warning");
//               } else {
//                   input.classList.add("warning");
//                   warningElement.classList.add("warning");
//               }
//               break;
          
//           case "email":
//               const userEmail = document.querySelector("#input-email").value;
//               let emailIsValid = (userEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g)) && (userEmail.length <= 60);
  
//               if (userEmail) {
//                   if (emailIsValid) {
//                       input.classList.remove("warning");
//                       warningElement.classList.remove("warning");
//                   } else {
//                       input.classList.add("warning");
//                       warningElement.classList.add("warning");
//                   }
//               } else {
//                   input.classList.remove("warning");
//                   warningElement.classList.remove("warning");
//               }
//               break;
  
//           case "message":
//               const userMessage = document.querySelector("#input-message").value;
//               let messageIsValid = (!userMessage.match(/(php|;|\$|xp_|drop table|\\|AUX|clock\$|com1|nul|prn|drop|table|<|<\/|>)/gi)) && (userMessage.length <= 500);
  
//               if (messageIsValid) {
//                   input.classList.remove("warning");
//                   warningElement.classList.remove("warning");
//               } else {
//                   input.classList.add("warning");
//                   warningElement.classList.add("warning");
//               }
//           break;
        
//         default:
  
//           break
//       }
  
       
//   }
  
//   function sendData(e) {
//       //prevent submit
//       e.preventDefault();
  
//       const username = document.querySelector("#input-username").value.trim();
//       const userEmail = document.querySelector("#input-email").value;
//       const date = document.querySelector("#input-date").value;
//       const time = document.querySelector("#input-hour").value;
//       const userMessage = document.querySelector("#input-message").value;
//       const inptFile = document.querySelector("#input-file").files[0];
  
//       //Check Valid Inputs
//       let usernameIsValid = (!username.match(/[-&\.;,=)_(&%$£"'!|\\/*-+.?^@°*0-9]/g)) && (username.length <= 20) && (username !== "");
//       let emailIsValid = (userEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g)) && (userEmail.length <= 20) && (userEmail !== "");
//       let messageIsValid = (!userMessage.match(/(php|;|\$|xp_|drop table|\\|AUX|clock\$|com1|nul|prn)/gi)) && (userMessage.length <= 500) && (userMessage !== "");
//       let IsSizeLessThen8 = inptFile ? inptFile.size < 8300000 : null;
//       let formIsValid = (usernameIsValid && emailIsValid && messageIsValid) || (usernameIsValid && emailIsValid && messageIsValid && IsSizeLessThen8);
      
//       // it's inputs are OK we can Send data
  
//          if (formIsValid) {
//               //formdata
//              const dateReversed = date.split("-").reverse().join("/");
             
//              const formData = new FormData();
//              formData.append("name", username);
//              formData.append("email", userEmail);
//              formData.append("date", dateReversed);
//              formData.append("time", time);
  
//              if (inptFile && inptFile.size < 8300000) {
//                   formData.append("img",inptFile)
//              }
             
//              ///NOW SEND DATA
//              //questo funziona per inviare foto
  
//              const url = "sendImgTattoo.php";
//              const site = "https://trueappwork.000webhostapp.com/"
             
//              const formDatas = new FormData();
             
             
//              const reader = new FileReader();
  
//              reader.onload = () => {
//                  reader.readAsDataURL(inptFile)
//              }
  
        
//              formDatas.append("img",reader.result);
//              formDatas.append("title", "il mio title");
//              formDatas.append("description", "descrizione");
          
                        
//              fetch(site + url, {
//                  method: "POST",
//                  body:formDatas
//              }).then(response => console.log(response.status))
             
  
//              document.querySelector(".form-warning-text").textContent = "";
  
//              //if it's no OK show warning
  
//          } else {
//              const warningNameText = "<br/> * Il nome deve contenere solo lettere, massimo 20 caratteri";
             
//              if (!usernameIsValid) {
//                  document.querySelector("#input-username").classList.add("warning");
//                  document.querySelector("#form-warning-text-name").classList.add("warning");
                 
//              }
//              if (!emailIsValid) {
//                  document.querySelector("#input-email").classList.add("warning");
//                  document.querySelector("#form-warning-text-email").classList.add("warning");
                 
//              }
  
//              if (!messageIsValid) {
//               document.querySelector("#input-message").classList.add("warning");
//               document.querySelector("#form-warning-text-message").classList.add("warning");
//              }
         
//          }   
       
//   }
  
  
  
  
//   /*
//   Handle File Input, Check the size if is < 8MB show warning message
//   --includes function ShowUpLoadImg to show the image next the input file
//   */
  
//   document.querySelector("#input-file").addEventListener("change", showUpLoadImg);
  
//   function showUpLoadImg() {
  
//       if (this.files[0]) {
//           const uploadElement = document.querySelector("#upload-container");
//           const containerInptFile = document.querySelector("label[for=input-file]");
//           const IsSizeLessThen8 = this.files[0].size < 8300000;
//           const warningText = document.querySelector("#form-warning-text-file");
  
//       if (IsSizeLessThen8) {
//           const imgFileUrl = URL.createObjectURL(this.files[0]);
//           uploadElement.style.backgroundImage = `url(${imgFileUrl})`;
//           console.log(imgFileUrl);
//           warningText.classList.remove("warning");
//           containerInptFile.classList.remove("warning");
  
//       } else {
//           this.files[0] = null;
//           warningText.classList.add("warning")
//           containerInptFile.classList.add("warning");
//       }
//   }
//   }
  
//   /*
//       Input Date & input Time functions
//   */
  
//   const inptDate = document.querySelector("#input-date");
//   const inptTime = document.querySelector("#input-hour");
  
  
//   function setDateInput() {
//       inptDate.addEventListener("change", showDate);
  
//   function showDate() {
//       const dateText = document.querySelector("label[for=input-date]");
  
//       if (inptDate?.value !== "") {
//           const date = new Date(inptDate.value);
//           dateText.textContent = date.toLocaleDateString("it-IT");
//           inptDate.style.outline = "0px solid black"
//       } else {
//           dateText.textContent = "Scegli il giorno"
//       }
  
//   }
  
//   inptDate.addEventListener("click", showBorder);
  
  
//   function showBorder() {
//       inptDate.style.outline = "1px solid white";
//   }
  
//   }
  
  
//   function setTimeInput() {
//       inptTime.addEventListener("input", showDate);
  
//   function showDate() {
//       const timeText = document.querySelector("label[for=input-hour]");
  
//       if (inptTime?.value !== "") {
         
//           timeText.textContent = inptTime.value;
//           inptTime.style.outline = "0px solid black"
//       } else {
//           timeText.textContent = "Scegli il giorno"
//       }
  
//   }
  
//   inptTime.addEventListener("click", showBorder);
  
  
//   function showBorder() {
//       inptTime.style.outline = "1px solid white";
//   }
  
//   }
  
  
//   setDateInput();
//   setTimeInput();
  
  


//   if (window.scrollY > 0) {
//       const header = document.querySelector("header");
//       header.style.top = "-100%"
//   }


//   function handleSliderScroll(slider) {
  
   
//     slider.addEventListener("mousedown", (e) => {
//         if (isMoving) {
//             isMoving = false;
//         }
//         mousePosInitialX = e.pageX;
//         isMouseHover = true;
//     })

//     slider.addEventListener("mouseup", (e) => {
//         if (isMoving) {
//             isScroll = true;
//             isMouseHover = false;
//         }
//         isScroll = false;
       
//     })

//     slider.addEventListener("mousemove", (e) => {  
//         const speed = 20;
       

//         if (isMouseHover) {
//             isMoving = true;
//             if (e.pageX < mousePosInitialX - 1) {
//                 slider.scrollLeft += speed;
//                 isScroll = true;

//             } else if(e.pageX > mousePosInitialX + 1) {
//                 slider.scrollLeft -= speed;
//                 isScroll = true;
//             }
//         }
//             mousePosInitialX = e.pageX;
      
//     })
// }

// function handleModale(imgs,tagType) {
//     imgs.forEach(img => {

//         img.addEventListener("mousedown", (e) => {
//             const mouseButtonleft = e.buttons !== 2;
           
//             if (mouseButtonleft && !isScroll) {

//                 img.addEventListener("mouseup", (e) => {
                   
//                     if (mouseButtonleft && !isMoving) {
//                             modaleImg.parentElement.parentElement.style.display = "flex";
//                             modaleImg.src = e.currentTarget.children[0].src;     
//                             imgTargetModale = e.currentTarget;
//                             typeOfTarget = tagType;
                         
//                         // Handle next & previous button depending of the next and previous tag Name of the target element

//                         //if tagtype isn't studio gallery nodename
//                         if (typeOfTarget !== "LI") {

//                             if (imgTargetModale.nextElementSibling === null || imgTargetModale.nextElementSibling.nodeName !== tagType) {
//                                 nextBtnModale.style.display = "none";
//                             } else {
//                                 nextBtnModale.style.display = "flex";
//                             }
               
//                             if (imgTargetModale.previousElementSibling === null || imgTargetModale.previousElementSibling.nodeName !== tagType) {
//                                 previousBtnModale.style.display = "none";
//                             } else {
//                                 previousBtnModale.style.display = "flex";
//                             }
                                
//                                   //if tagtype is studio gallery nodename
//                         } else {
                                
//                                 if (imgTargetModale.nextElementSibling === null || !imgTargetModale.nextElementSibling.children[0]) {
//                                     nextBtnModale.style.display = "none";
//                                 } else {
//                                     nextBtnModale.style.display = "flex";
//                                 }  
        
//                             if ( imgTargetModale.previousElementSibling.children[0].nodeName !== "IMG") {
//                                 previousBtnModale.style.display = "none";
//                             } else {
//                                 previousBtnModale.style.display = "flex";
//                             }
//                         }
                
//                     }
//                     isScroll = false;
//                     isMouseHover = false;
                   
//                 })
            
//                 img.parentElement.parentElement.parentElement.addEventListener("mouseleave", () => {
                   
//                     isScroll = false;
//                     isMouseHover = false;
//                     isMoving = false;
//                 })
//         }    
//         })  
        
//         handleSliderScroll(img.parentElement.parentElement.parentElement)
// })       
// }


// function next() {
//     // Handle next & previous button depending of the next and previous tag Name of the target element
//     if (typeOfTarget !== "LI") {

//         if (imgTargetModale.nextElementSibling === null || imgTargetModale.nextElementSibling.nodeName !== typeOfTarget) {
//             nextBtnModale.style.display = "none";
//         } else {
//             nextBtnModale.style.display = "flex";
//             modaleImg.src = imgTargetModale.nextElementSibling.children[0].src;
//             imgTargetModale = imgTargetModale.nextElementSibling; 
//             if (imgTargetModale.nextElementSibling === null) {
//                 nextBtnModale.style.display = "none";
//             }
//         }

//         if (imgTargetModale.previousElementSibling === null || imgTargetModale.previousElementSibling.nodeName !== typeOfTarget) {
//             previousBtnModale.style.display = "none";
//         } else {
//         previousBtnModale.style.display = "flex";
//         }
        
        
//     } else {
       
//         if (imgTargetModale.nextElementSibling.children[0]) {
//                 nextBtnModale.style.display = "flex";
//                 modaleImg.src = imgTargetModale.nextElementSibling.children[0].src;
//                 imgTargetModale = imgTargetModale.nextElementSibling; 
//             if (imgTargetModale.nextElementSibling=== null) {
//                 nextBtnModale.style.display = "none";
//             }
//         } else {
//             nextBtnModale.style.display = "none";
//         }

//         if (imgTargetModale.previousElementSibling === null || imgTargetModale.previousElementSibling.nodeName !== typeOfTarget) {
//             previousBtnModale.style.display = "none";
//         } else {
//             previousBtnModale.style.display = "flex";
//         }
//     } 
    
    
// }
    

// function previous() {
    
//      // Handle next & previous button depending of the next and previous tag Name of the target element
//      if (typeOfTarget !== "LI") {

//         if (imgTargetModale.previousElementSibling === null || imgTargetModale.previousElementSibling.nodeName !== typeOfTarget) {
//            previousBtnModale.style.display = "none";
//         } else {
//            previousBtnModale.style.display = "flex";
//             modaleImg.src = imgTargetModale.previousElementSibling.children[0].src;
//             imgTargetModale = imgTargetModale.previousElementSibling;  
//             if (!imgTargetModale.previousElementSibling || imgTargetModale.previousElementSibling.nodeName !== typeOfTarget) {
//                 previousBtnModale.style.display = "none";
//             }
//         }

//         if (imgTargetModale.nextElementSibling === null || imgTargetModale.nextElementSibling.nodeName !== typeOfTarget) {
//             nextBtnModale.style.display = "none";
//         } else {
//             nextBtnModale.style.display = "flex";
//         }
        
        
//     } else {
        
//         if (imgTargetModale.previousElementSibling &&  imgTargetModale.previousElementSibling.children[0] ) {
//             if (imgTargetModale.previousElementSibling === null || imgTargetModale.previousElementSibling.children[0].nodeName !== "IMG") {
//                previousBtnModale.style.display = "none";
//             } else {
//                previousBtnModale.style.display = "flex";
//                 modaleImg.src = imgTargetModale.previousElementSibling.children[0].src;
//                 imgTargetModale = imgTargetModale.previousElementSibling; 
//                 if (imgTargetModale.previousElementSibling.children[0].nodeName !== "IMG") {
//                     previousBtnModale.style.display = "none";
//                  }
//             }
    
//             if (imgTargetModale.nextElementSibling === null || imgTargetModale.nextElementSibling.children[0].nodeName !== "IMG") {
//                 nextBtnModale.style.display = "none";
//             } else {
//                 nextBtnModale.style.display = "flex";
//             }
//         }  
//     } 

          
// }

// // general carousel
// handleModale(document.querySelectorAll(".carousel div"), "DIV");
// // artist carousels
// handleModale(document.querySelectorAll(".img-container--slider-tatuatori"), "FIGURE");
// // studio carousel
// handleModale(document.querySelectorAll(".studio-image-list"), "LI");

// nextBtnModale.addEventListener("click", next);
// previousBtnModale.addEventListener("click", previous);


// //On click exit button -> display none
// modaleExitBtn.addEventListener("click", () => {
//     modaleImg.parentElement.parentElement.style.display = "none";
//     typeOfTarget = "";
// })

// }})





