import React, { useState } from 'react'

const TattooImg = ({ path, description,setImgSize,onClick }) => {
  const [imgSizeISDefined, setImgSizeISDefined] = useState(null)
  

  function checkImage(e) {
    e.target.style.display = "none"
  }
  
  
  return (
    <div className="img-card-container"onClick={onClick} >
      <img
        src={`assets/images/uploads/${path}`}
        onError={checkImage}
        alt={description}
        draggable="false"
        className="img-carousel-galleria"
        onLoad={(e) => {
          setImgSize(e.target.width);
          setImgSizeISDefined(true)
      }}
      />
    </div>
  )
}

export default TattooImg
