import React from 'react'
import { useQuery } from 'react-query'
import api from '../../../../Axios/Axios'
import Title from '../../Reusables/Title/Title'
import { Link, useNavigate } from 'react-router-dom'


const GeneralSetting = () => {
    const { isLoading, data } = useQuery("generalSettings", () => {
        return api.get("studio").then(res => res.data)
    })

    const navigate = useNavigate()
    
  return (
      <div>
          <Title title={"Settaggi Generali"} />
          <Link className='p-5' to={"/admin/home"}>Indietro</Link>
          {isLoading && <p>loading</p>}
          <div className="container-fluid mt-5">
          <table className='table table-bordered table-responsive slider d-block admin-table '>
                    <thead>
                            <tr>
                                <th>ID</th>
                                <th>Email</th>
                                <th>Indirizzo</th>
                                <th>Facebook</th>
                                <th>Instagram</th>
                                <th>Telefono</th>
                                <th>P.IVA</th>
                                <th>Modifica</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                               <td>{data?.id}</td>
                                <td>{data?.email}</td>
                                <td>{data?.address}</td>
                                <td>{data?.facebook}</td>
                                <td>{data?.instagram}</td>
                                <td>{data?.tel}</td>
                                <td>{data?.iva_number}</td>    
                                <td style={{cursor:"pointer"}} onClick={()=>navigate("/admin/general-settings/edit",{state: data}) }><img src='/assets/icons/edit.svg' alt=''/></td>    
                            </tr>
           
                      </tbody>
            </table>
        </div>
      
    </div>
  )
}

export default GeneralSetting
