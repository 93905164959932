import React, { useState } from 'react'
import Title from '../../../Reusables/Title/Title'
import { Link, useNavigate } from 'react-router-dom'
import FormContainer from '../../../Reusables/Form/Form'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import api from '../../../../../Axios/Axios'

const NewAlbum = () => {
  const [name, setName] = useState("")
  const navigate = useNavigate()

  function createAlbum(e) {
    e.preventDefault()
    api.post("gallery", {albumName: name }).then(res => {
      console.log(res.data);
      if (res.data === "success") {
        navigate("/admin/albums")
      }
    })
  }

  return (
    <div>
      <Title title={"Nuovo Album"} />
      <Link className='p-2' to={"/admin/home"}>Indietro</Link>
      <div className='container mt-4' >
        <FormContainer submitBtnText={"Crea Album"} onSubmit={createAlbum}>
          <FormGroup className='mb-4'>
            <FormLabel htmlFor='name'>Nome Album</FormLabel> 
            <FormControl type='text' name='name' id='name' placeholder={"Inserire il nome dell'album"} value={name} onChange={(e)=>setName(e.target.value)}/>
          </FormGroup>
        </FormContainer>
      </div>
      
    </div>
  )
}

export default NewAlbum
