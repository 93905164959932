import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({ bool }) => {
    if (bool === true) {
        return (
            <div>
                <Outlet/>
            </div>
          )
    } else {
        return <Navigate to={"/"}/>
    }
  
}

export default ProtectedRoute
