import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import api from '../../../../Axios/Axios'
import {Link, useNavigate} from 'react-router-dom'
import InputEditor from '../../Reusables/InputEditor/InputEditor'
import { Col, FormGroup, FormLabel, FormSelect, Row, Toast, ToastContainer } from 'react-bootstrap'
import FormContainer from '../../Reusables/Form/Form'
import Title from '../../Reusables/Title/Title'

const Authors = () => {
    const [showEditor, setShowEditor] = useState(false);
    const [editor,setEditor] = useState({id:"",lastAlbumId:"",})
    const [albums, setAlbums] = useState([])
    const navigate = useNavigate()
    const [dataAuthors, setDataAuthors] = useState([])
    const [assignAlbumId, setAssignAlbumId] = useState("");
    const [showToast, setShowToast] = useState(false)
    const [toastTitle,setToastTitle] = useState("")

    const { isLoading, isError, data, error,refetch } = useQuery("author", () => {
        return api.get("author").then(response => response.data)
    })

    useEffect(() => {
    if (data) {
        setDataAuthors(data)
    }
    }, [data])

    
    function editInput(id,lastAlbum) {
        setEditor({id:id,lastAlbumId:lastAlbum})
      console.log(editor);
        api.get("gallery").then(res => {
            const allAlbums = [...res.data]
            setAlbums(allAlbums)
        })
        
        if (albums) {
            setShowEditor(true);
        }
       
    }

    function assingId(e) {
        e.preventDefault()
        if (assignAlbumId && editor.id) {
            console.log(assignAlbumId,editor);
            api.patch("author", {
                albumId: +assignAlbumId,
                id: editor.id,
                lastAlbumId: editor.lastAlbumId
            })
                .then(res => {
                    console.log(res.data);
                if (res.data === "success") {
                    console.log("siiii");
                    setShowEditor(false)
                    refetch()
                }
            })
        } else {
            console.log("scegli un album");
        }
    }


    function toggleActiveAuthor(index, id, isActive) {
        console.log(index,id,isActive);
        const active = isActive === 1 ? 0 : 1
        api.patch("author/" + id, { isActive: active }).then(res => {
            if (res.data === "success") {
                dataAuthors[index].isActive = active;
                console.log(dataAuthors[index]);
                setDataAuthors([...dataAuthors])
                if (active === 0) {
                    setToastTitle("Disattivato con successo")
                } else if (active === 1) {
                    setToastTitle("Attivato con successo")
                }
                setShowToast(true)
            }
        })
        
    }

    function deleteAuthor(id) {
        const confirm = window.confirm("Vuoi eliminare questo Autore e le sue rispettive foto?")
        if (confirm) {
            api.delete("author/" + id).then(res => {
                if (res.data === "success") {
                    refetch()
                }
            })
        }
    }

    


    
    return (
        <div>
            <Title title={"Artisti"}/>
            <Link className='p-5' to={"/admin/home"}>Indietro</Link>
            <Row>
        <Col
         xs={6}>
            <ToastContainer position='top-center' style={{position:"fixed"}}>
                <Toast  className={"bg-success"} onClose={() => setShowToast(false)} onClick={()=>setShowToast(false)} show={showToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Success</strong>
                    </Toast.Header>
                <Toast.Body style={{color:"white"}}> <p>{toastTitle}</p></Toast.Body>
                </Toast>
            </ToastContainer>
    </Col>
    
        </Row>
            {showEditor &&
                <InputEditor
                title={albums.length > 0 ? "Scegli l'album" : "Non ci sono Album disponibili"}
                onClose={() => setShowEditor(false)} 
                >
             
                {albums.length > 0 ?
                    <FormContainer submitBtnText={"Scelto"} onSubmit={(e)=> assingId(e)}>
                    <div className='mt-5 mb-3 '>
                        <FormGroup>
                            <FormLabel>Albums</FormLabel>
                                    <FormSelect value={assignAlbumId} onChange={(e) => setAssignAlbumId(e.target.value)}>
                                <option value=""></option>
                                <option value="null">null</option>
                                { albums?.map((a, i) => 
                                    <option value={a.id}>{a.album_name}</option>
                                )
                            }
                            </FormSelect>
                        </FormGroup>
                    </div>
                    </FormContainer>
                    :
                    <div>
                        <p className='text-center mt-5 h5'><Link to={"/admin/new-album"}>Crea nuovo album</Link></p>
                    </div>
                    
                }
            </InputEditor>
            
            }

            <div style={showEditor ? {opacity:"0.1"}: {}}>
                {isLoading && <p>loading</p>}
                <table  className='table table-responsive p-2 slider d-block admin-table'>
                        <thead className='text-center'>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Nome d'arte</th>
                                    <th>Nome in Card</th>
                                    <th>Foto</th>
                                    <th>Telefono</th>
                                    <th>Instagram</th>
                                    <th>Facebook</th>
                                    <th>Attivo</th>
                                    <th>Modifica</th>
                                    <th>Elimina</th>
                                </tr>
                            </thead>

                            <tbody className='text-center'>
                            {data && data.map((author, i) => {
                                return (
                                            <tr key={i}>
                                        {
                                            author.albumId ?
                                                <td className='border-1' onClick={()=>editInput(author.id,author.albumId)}>{author.albumId}</td>
                                                :
                                                <td className='border-1 text-decoration-underline text-primary'>
                                                    <span style={{ cursor: "pointer" }} onClick={()=>editInput(author.id,author.albumId)}>NULL</span>
                                                </td>
                                        }
                                                <td className='border-1'>{author.name}</td>
                                                <td className='border-1'>{author.stageName}</td>
                                                <td className='border-1'>{author.nameInCard}</td>
                                                <td className='border-1'>{author.imagePath}</td>
                                                <td className='border-1'>{author.tel}</td>
                                                <td className='border-1'>{author.instagram}</td>
                                                <td className='border-1'>{author.facebook}</td>
                                                <td className='border-1' style={{ cursor: "pointer" }} onClick={() => toggleActiveAuthor(i,author.id,author.isActive)}><input type="checkbox" checked={dataAuthors[i]?.isActive} onChange={(e)=>(!e.target.checked)}/></td>
                                                <td className='border-1' style={{cursor:"pointer"}} onClick={()=> navigate(`/admin/author/${author.id}`,{state:author})}><img src="/assets/icons/edit.svg" alt="" /></td>
                                                <td  className='border-1' style={{cursor:"pointer"}} onClick={()=>deleteAuthor(author.id)}><img src="/assets/icons/delete.svg" alt=""  /></td>
                                            </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </div>
        </div>
    )
}

export default Authors
