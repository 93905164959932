import React from 'react'

const CarouselBtn = ({index,move}) => {
  return (
   
    <div className="btns-container">
    <div className={`btn-gallery btn-gallery--left btn-gallery-${index}`} onClick={()=>move("left")}>
        <div className="arrow-btn-gallery-container">
            <span className="arrow-line arrow-line--left"></span>
        </div>
    </div>
    <div className={`btn-gallery btn-gallery--right btn-gallery-${index}`}  onClick={()=>move("right")}>
        <div className="arrow-btn-gallery-container">
            <span className="arrow-line arrow-line--right"></span>
        </div>
    </div>
   </div>
    
  )
}

export default CarouselBtn
