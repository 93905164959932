import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import FormContainer from '../../Reusables/Form/Form'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import Title from '../../Reusables/Title/Title'
import api from '../../../../Axios/Axios'

const GeneralSettingEdit = () => {
    const location = useLocation()
    const [email, setEmail] = useState(location?.state?.email)
    const [address,setAddress] = useState(location?.state?.address)
    const [facebook,setFacebook] = useState(location?.state?.facebook)
    const [instagram,setInstagram] = useState(location?.state?.instagram)
    const [tel,setTel] = useState(location?.state?.tel)
    const [iva,setIva] = useState(location?.state?.iva_number)

    
    function submit(e) {
        e.preventDefault()

        const data = {
            email,
            address,
            facebook,
            instagram,
            tel,
            iva,
            id: location?.state?.id
        }

        api.put("studio", { ...data })
        .then(res => console.log(res))
    }
    
    
  return  (
      <div>
          <Title title={"Modifica - General Settings"} />
          <Link className='p-5' to={"/admin/general-settings"}>Indietro</Link>
          <div className='container mt-5'>

          <FormContainer submitBtnText={"Modifica"} onSubmit={(e)=>submit(e)}>
            <FormGroup>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <FormControl required type='email' name='email' id='email' value={email} onChange={(e)=> setEmail(e.target.value)} />
            </FormGroup>

                  <FormGroup className='mt-4'>
                        <FormLabel htmlFor='address'>Indirizzo</FormLabel>
                        <FormControl required type='text' name='address' id='address' value={address} onChange={(e)=> setAddress(e.target.value)} />
                  </FormGroup>
                  
            <FormGroup className='mt-4'>
                  <FormLabel htmlFor='facebook'>Facebook</FormLabel>
                  <FormControl required type='url' name='facebook' id='facebook' value={facebook} onChange={(e)=> setFacebook(e.target.value)} />
            </FormGroup>
                  
            <FormGroup className='mt-4'>
                  <FormLabel htmlFor='instagram'>Instagram</FormLabel>
                  <FormControl required type='url' name='instagram' id='instagram' value={instagram} onChange={(e)=> setInstagram(e.target.value)} />
            </FormGroup>
                  

            <FormGroup className='mt-4'>
                  <FormLabel htmlFor='tel'>Telefono</FormLabel>
                  <FormControl required type='tel' name='tel' id='tel' value={tel} onChange={(e)=> setTel(e.target.value)} />
                  </FormGroup>
                  
                <FormGroup className='mt-4 mb-4'>
                    <FormLabel htmlFor='iva'>P.IVA</FormLabel>
                    <FormControl required type='text' name='iva' id='iva' value={iva} onChange={(e)=> setIva(e.target.value)} />
                </FormGroup>
              
        </FormContainer>
          </div>
         
    </div>
  )
}

export default GeneralSettingEdit
