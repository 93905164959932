import React, { useState } from 'react'
import Title from '../../Reusables/Title/Title'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import api from '../../../../Axios/Axios'

const Albums = () => {
  const [notification,setNotification] = useState({})
  const { data,refetch } = useQuery("admin-albums", () => {
    return api.get("gallery").then(res => res.data)
  })

  const navigate = useNavigate()

  function deleteAlbum(id){
    const confirm = window.confirm("Vuoi veramente eliminare quest'album ? ");
    if (confirm) {
      api.delete("gallery/" + id).then(res => {
          if (res.data === "success") {
            refetch()
            setNotification({status:1,title:"Eliminato con successo"});
          } else {
            setNotification({status:0,title:"Errore",message:"Impossibile eliminare l'album, ritenta"});
          }
      }).finally(setTimeout(() => {
          setNotification({})
      }, 3000))
    }  
  }

 
  return (
    <div>
          <Title title={"Gallerie Foto"} />
          <Link className='p-2' to={"/admin/home"}>Indietro</Link>
          {notification.title &&
              <div className={`position-fixed top-0 container-fluid rounded-0  ${notification?.status === 0 ? "alert alert-danger" : "alert alert-success"}`} onClick={ () => setNotification({})}>
                  <p className='h4 text-center'>{notification?.title}</p>
                  <p className='text-center'>{notification?.message}</p>
                  {notification?.errors && notification.errors.map((e, i) => 
                    <>
                          <p className='text-center'>- {e}</p>
                    </>
                  )  
                }
            </div>
          }
      <div className='container-fluid d-grid justify-content-center'>
            <table className='table table-responsive p-2 slider d-block admin-table' >
                        <thead className='text-center'>
                                <tr>
                                    <th>Nome Album</th>
                                    <th>Modifica</th>
                                    <th>Elimina</th>
                                </tr>
                            </thead>

                            <tbody className='text-center'>
                            {data && data.map((album, i) => {
                              return (
                                <tr key={i}>
                                  <td className='border-1'>{album.album_name}</td>
                                  <td className='border-1 hover-effect' onClick={()=> navigate("/admin/albums/" + album.id,{state: album.album_name})} style={{ cursor: "pointer" }} >
                                    <img src="/assets/icons/edit.svg" style={{ width: 50 }} width={50} alt="" />
                                  </td>
                                  <td className='border-1 hover-effect' onClick={()=> deleteAlbum(album.id)} style={{ cursor: "pointer" }} >
                                      <img src="/assets/icons/delete.svg" style={{ width: 50 }} width={50} alt="" />
                                  </td>  
                                </tr>
                              )
                          })}
                        </tbody>
                    </table>
      </div>
      <div className='container'>
        <Link className='text-center w-25 border border-1 btn btn-primary d-block  m-auto' to={"/admin/new-album"} style={{minWidth:150,maxWidth:200}}>Crea Album</Link>
      </div>
      
    </div>
  )
}

export default Albums
