import React from 'react'
import api from '../../../../Axios/Axios'
import { useQuery } from 'react-query'

const OpeningTimes = () => {
const { isLoading, isError, data, error } = useQuery("times", () => {
        return api.get("openingtimes").then(response => response.data)
})
  
  console.log(data && data);

  return data && (
    <div className="info-container info-container3">
    <p className="info-title">ORARI D'APERTURA</p>

    <div className="info-container-item info-item-container">
        <div className="icon-item icon-item--6"></div>
        <p className="text-info-item text-info-item-openTime">
          {data[0]?.open ? <b>LUN-VEN:<time>{data[0]?.start_at}</time>-<time>{data[0]?.end_at}</time>&nbsp; &nbsp; </b> :  <b>LUN-VEN:<time>{" Chiuso "}</time> </b>}
          {data[1]?.open ? <b>SAB:<time>{data[1]?.start_at}</time>-<time>{data[1]?.end_at}</time>&nbsp; &nbsp; </b> : <> <br/> <b>SAB:<time>{" Chiuso "}</time> </b> </>}
          <br/>
          {data[2]?.open ? <b>DOM:<time>{data[2]?.start_at}</time>-<time>{data[2]?.end_at}</time>&nbsp; &nbsp; </b> :  <b>DOM:<time>{" Chiuso"}</time> &nbsp; &nbsp;</b>} 
        </p>
    </div>

    <p className="info-title info-title-map">DOVE TROVARCI</p>
    <a href="https://goo.gl/maps/zdZJBRKq1FKAC7YL9" rel='noreferrer'  target="_blank"  className="map-$">
    <div className="map" title="Trovaci su Google Maps"></div>
    </a>
</div>
  )
}

export default OpeningTimes
