import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const FormContainer = ({ onSubmit, children, formType, submitBtnText }) => {
  return (
    <div className="container ">
      <Form onSubmit={onSubmit}>
        {children}
        <center>
          <Button
            variant="primary"
            type="submit"
            className="btn btn-lg m-3 m-auto w-50"
          >
            {submitBtnText}
          </Button>
        </center>
      </Form>
    </div>
  );
};

export default FormContainer;
