import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import api from '../../../../Axios/Axios'
import Gallery from './Gallery'
import { modalContext } from '../../PublicRoute'


const SectionTatuatori = () => {
    const {isLoading, isError, data, error } = useQuery("artist", () => {
        return api.get("author").then((result) => result.data)
    })
    const {modalPath,setModalPath} = useContext(modalContext)
    console.log(data);
   
    return data && data.length > 0 && (
        <section className="page" id="tatuatori">
            <div className="inner-gallery-page inner-page tatuatori-container">
            <section className="inner-page-text-section padding-140">
                <p className="title-page">TATUATORI</p>
                <h2 className="description-title-page white-rose--left">Gli artisti di Black Rose</h2>
                <hr className="separator-in-page" />
            </section>
            {data?.map((author, index) => 
                author.albumId && author.isActive === 1 && 
            <div>
                <Gallery
                setModalPath={setModalPath}
                key={index}
                author={author}
                index={index + 1}
                />
            </div>        
            
        )}

            </div>  
        </section>
      
  )
}

export default SectionTatuatori
