import React from 'react'

const Title = ({title}) => {
  return (
    <div>
      <h1 className='h3 mb-3 pt-3 pb-3 text-center text-bg-primary'>{title}</h1>
    </div>
  )
}

export default Title
