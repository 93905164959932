import React from 'react'

const Main = ({children}) => {
  return (
    <main className='main'>
      <div className='innerMain'>
         {/* <!--White Rose Bottom (Second on the left)--> */}
      <div className="white-rose--left--bottom"></div>

      {/* <!--White Rose Right--> */}
      <div className="white-rose--right"></div>
        {children}
      </div>
    </main>
  )
}

export default Main
