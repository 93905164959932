import React, { useState } from "react";
import Title from "../../../Reusables/Title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import api from "../../../../../Axios/Axios";
import FileResizer from "react-image-file-resizer";
import FormContainer from "../../../Reusables/Form/Form";
import { FormControl, FormGroup, FormLabel, FormText } from "react-bootstrap";

const EditAlbum = () => {
  const param = useParams();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [newImage, setNewImage] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  console.log(newImage);
  const { data, refetch } = useQuery("editAlbum", () => {
    return api.get("image/" + param.id).then((res) => res.data);
  });

  async function resizedFile(image) {
  return await resizeFile(image)
  
  }
  

  async function addImage(e) {
     e.preventDefault();
    if (newImage) {
      const formdata = new FormData()
      newImage.forEach(async (element,i) => {
        const resizedImage = await resizedFile(element)
        formdata.append("newImage[]", resizedImage)
        if (i === newImage.length -1) {
          formdata.append("title", title);
         formdata.append("description", description);
          formdata.append("id", param.id);
          api
          .post(
            "image",
            formdata,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data === "success") {
              closeModal();
              refetch();
            }
          });
        }
      });
     
   
    
    // 
        

  
      //  if (images.length > 0) {
       
      //    
      //  }
      
       
      }
    
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      param.id !== 1 
      ?
      FileResizer.imageFileResizer(
        file,
        397,
        560,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
        )
        :
        FileResizer.imageFileResizer(
          file,
          260,
          345,
          "WEBP",
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
          )
        ;
    });

  function closeModal() {
    setShowModal(false);
    setTitle("");
    setDescription("");
    setNewImage(null);
  }

  function deleteImage(id, path) {
    api.delete("image/" + id).then((res) => {
      if (res.data === "success") {
        refetch();
      }
    });
  }

  return (
    <div className="w-100 h-100 pb-3 mb-3" style={{ width: "100vw", height: "100vh" }}>
      <div className="w-100 h-100" style={showModal ? { display: "none" } : {}}>
        <Title title={"Modifica Album"} />
        <Link className="p-2" to={"/admin/albums"}>
          Indietro
        </Link>
        <p className="text-center h3 mb-5">{location?.state}</p>
        {!data && (
          <p className="text-center h3">Non ci sono immagini al momento</p>
        )}
        <div className=" d-flex flex-wrap gap-3 justify-content-center ">
          {data &&
            data?.map((img, i) => {
              return (
                <div
                  className="position-relative"
                  style={data ? { display: "block" } : { display: "none" }}
                >
                  <div
                    className="border border-danger d-flex justify-content-center align-items-center text-light position-absolute end-0 bg-danger z-2"
                    style={{
                      width: 20,
                      height: 20,
                      transform: "translate(-10px,10px)",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteImage(img.id, img.path)}
                  >
                    X
                  </div>
                  <img
                    src={"/assets/images/uploads/" + img.path}
                    alt=""
                    style={{ width: 150, height: 200 }}
                  />
                </div>
              );
            })}
        </div>

        <div>
          <p
            className="btn btn-primary rounded-5 position-fixed bottom-0 start-50 translate-middle p-2"
            onClick={() => setShowModal(!showModal)}
          >
            Aggiungi una nuova Immagine
          </p>
        </div>
      </div>

      {showModal && (
        <>
          <div className="position-absolute  top-0 start-0 w-100 z-3 bg-white pb-4 h-100 d-flex justify-content-center align-items-center">
           
            <div className="w-100 h-100 bg-light container">
            <div
              className="btn-close btn float-end end-0 top-0 m-3"
              onClick={closeModal}
            ></div>
              <div className="h4 mt-5 text-center">Aggiungi Immagine</div>
              <div className="d-flex flex-column pb-5">
                <FormContainer
                  submitBtnText={"Aggiungi"}
                  onSubmit={(e) => addImage(e)}>
                        <FormGroup className="mt-3">
                        <FormLabel htmlFor="title">Titolo</FormLabel>
                        <FormControl
                          className="border border-black"
                        
                          type="text"
                          name="title"
                          id="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup className="mt-3">
                        <FormLabel htmlFor="description">Descrizione</FormLabel>
                        <FormText className=" d-block">
                          <textarea
                            className="w-100 p-1"
                            cols="10"
                            rows="3"
                            name="description"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </FormText>
                      </FormGroup>
                
                  <FormGroup className="mt-3 mb-5">
                    <FormLabel htmlFor="description">Immagine</FormLabel>
                    <FormControl
                      className="border border-black d-block"
                      required
                      type="file"
                      name="description"
                      id="description"
                      onChange={(e) => setNewImage([...e.target.files])}
                      accept="image/jpeg, image/png"
                      multiple={param.id === "1" && true}
                    />
                  </FormGroup>
                  <center className="mb-5 ">
                  {newImage?.map ( (img,i) =>
                    <img
                      src={URL.createObjectURL(img)}
                      alt=""
                      className="m-2 border border-3 border-dark"
                      style={{ width: 100, height: 150 }}
                    />
                  )}
                  </center>
                 
                </FormContainer>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditAlbum;
