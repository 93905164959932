import React from 'react'
import NewAuthorForm from './NewAuthorForm'
import { Link } from 'react-router-dom'
import Title from '../../../Reusables/Title/Title'


const NewAuthor = () => {
  return (
    <div>
      <Title title={"Nuovo Artista"}/>
        <Link className='p-5' to={"/admin/home"}>Indietro</Link>
        <NewAuthorForm/>
    </div>
  )
}

export default NewAuthor
